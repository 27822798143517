import glamorous from 'glamorous';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Fragment } from 'react';
import h from 'react-hyperscript';

const tableStyle = {
    borderCollapse: 'collapse',

    [`& thead`]: {
        [`& tr`]: {
            textAlign: 'left',
        },
    },

    [`& tr`]: {
        borderBottom: '1px solid #DFE2E5',
    },

    [`& th`]: {
        padding: '1em 0.5em 1em 0.5em',
    },

    [`& tbody`]: {
        [`& tr`]: {
            ':hover': {
                backgroundColor: '#FFF4C9',
            },
        },
    },

    [`& td`]: {
        padding: '1em 0.5em 1em 0.5em',

        [`& .tdBefore`]: {
            display: 'none',
        },
    },

    '@media screen and (max-width: 40em)': {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        display: 'block',

        [`& thead`]: {
            display: 'block',

            [`& tr`]: {
                position: 'absolute',
                top: '-9999px',
                left: '-9999px',
                borderBottom: '2px solid #333',
            },
        },

        [`& tr`]: {
            display: 'block',
        },

        [`& th`]: {
            display: 'block',
        },

        [`& tbody`]: {
            display: 'block',

            [`& tr`]: {
                padding: '.25em',
            },
        },

        [`& td`]: {
            display: 'block',
            fontWeight: 400,

            [`&.pivoted`]: {
                border: 'none !important',
                position: 'relative',
                paddingLeft: 'calc(50% + 10px) !important',
                textAlign: 'left !important',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
            },

            [`& .tdBefore`]: {
                color: '#525C66',
                position: 'absolute',
                display: 'block',
                left: '1rem',
                width: 'calc(50% - 20px)',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                textAlign: 'left !important',
            },
        },
    },
};

const WrappedTable = glamorous(Table)(tableStyle);

const RedemptionsList = ({ tableData }) => {
    const tableBody = tableData.map((redemption) => {
        return h(
            Tr,
            [],
            [
                h(Td, [], redemption.travelDates),
                h(Td, [], redemption.redemptionHotelName),
                h(Td, [], redemption.bookingId),
                h(Td, [], redemption.expirationDate),
                h(Td, [], redemption.voucherValue),
                h(Td, [], redemption.redemptionStatus),
                h(Td, [], redemption.premiumStatus),
            ],
        );
    });

    return h(
        Fragment,
        [],
        [
            h(WrappedTable, {}, [
                h(
                    Thead,
                    [],
                    [
                        h(
                            Tr,
                            [],
                            [
                                h(Th, [], 'Reisezeitraum'),
                                h(Th, [], 'Hotel'),
                                h(Th, [], 'HolidayCheck-Buchungsnummer'),
                                h(Th, [], 'Gültig bis'),
                                h(Th, [], 'Wert'),
                                h(Th, [], 'Status'),
                                h(Th, [], ' '),
                            ],
                        ),
                    ],
                ),
                h(Tbody, [], tableBody),
            ]),
        ],
    );
};

export default RedemptionsList;
