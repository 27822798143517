import options from '@designsystem/options';

export const black = '#000820';
export const blue = '#0E55CD';
export const blue20 = '#09337B';
export const blue40 = '#0C48B0';
export const blue90 = '#CFE0FB';
export const blue100 = '#F3F7FD';
export const gray = '#8494B2';
export const gray20 = '#3B4B66';
export const gray40 = '#556685';
export const gray90 = '#D9E1EE';
export const gray100 = '#F6F8FC';
export const green = '#00A396';
export const green20 = '#005A52';
export const green40 = '#008278';
export const green90 = '#BFE8E5';
export const green100 = '#F2FAFA';
export const orange = '#FF5E24';
export const orange20 = '#8C3414';
export const orange40 = '#CC4B1D';
export const orange90 = '#FFD7C8';
export const orange100 = '#FFF7F4';
export const red = '#F03A47';
export const red20 = '#842027';
export const red40 = '#C02E39';
export const red90 = '#FCD7DA';
export const red100 = '#FEF5F6';
export const watermelon = '#FB5879';
export const watermelon20 = '#8A3043';
export const watermelon40 = '#C94661';
export const watermelon90 = '#FED5DD';
export const watermelon100 = '#FFF7F8';
export const white = '#FFFFFF';
export const yellow = '#FFCA3A';
export const yellow20 = '#8C6F20';
export const yellow40 = '#CCA22E';
export const yellow90 = '#FFF2CE';
export const yellow100 = '#FFFCF5';

export const drivebooGradient =
    'linear-gradient(90deg, #00A396 0%, #008278 100%)';

export const premiumGradient =
    'linear-gradient(90deg, #9624B2 0%, #50449C 100%)';
export const premiumGradientHover =
    'linear-gradient(90deg, #810694 0%, #3C3088 100%)';

export const blueGradient = `linear-gradient(90deg, ${options.colors.blue} 0%, ${options.colors.blue20} 100%), ${options.colors.blue}`;
export const voucherGradient = `linear-gradient(90deg, ${blue} 0%, ${blue20} 100%)`;

export const blackWithOpacity = (opacity) => `rgba(0, 8, 32, ${opacity})`;
export const greyWithOpacity = (opacity) => `rgba(132, 148, 178, ${opacity})`;
