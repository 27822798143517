import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import options from '@designsystem/options';
import { BestOfferIcon } from '@designsystem/components';

import { waveTextS, waveTextSBold } from '../../../styles/waveText';

const Title = glamorous.div({
    ...waveTextSBold,
    color: options.colors.white,
    marginBottom: options.space['2xs'],
});

const SubHeadline = glamorous.div({
    ...waveTextS,
    color: options.colors.white,
    marginBottom: options.space.m,
});

const BackgroundImage = glamorous.div(
    {
        display: 'flex',
        flexDirection: 'column',
        padding: `${options.space.xl}px ${options.space.l}px`,
        borderRadius: `${options.space.xs}px`,
    },
    ({ backGroundImageLink }) => ({
        background: `linear-gradient(90deg, rgba(0, 130, 120, 0.40) 0%, rgba(0, 90, 82, 0.40) 100%), url(${backGroundImageLink}), linear-gradient(90deg, #008278 0%, #005A52 100%)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: `normal, luminosity, normal`,
    }),
);

const MemberRates = glamorous.div({
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'start',
    gap: options.space['2xs'],
    padding: `${options.space['2xs']}px ${options.space.xs}px`,
    backgroundColor: options.colors.green90,
    borderRadius: `${options.space['2xs']}px`,
    color: options.colors.green20,
});
const MemberRatesLabel = glamorous.div({
    ...waveTextS,
    fontWeight: options.fontWeights.mediumBold,
});

const BookingsCard = (props, { config }) => {
    const backGroundImageLink = `${config.assetsPath}/myHolidayCheck/hero-img.png`;
    return h(BackgroundImage, { backGroundImageLink }, [
        h(Title, 'Sichere Dir Deinen MeinHolidayCheck-Vorteil!'),
        h(
            SubHeadline,
            `Du erhältst nun exklusive Rabatte von bis zu 15% auf verschiedene Hotelbuchungen. Halte einfach Ausschau nach diesem Symbol:`,
        ),
        h(MemberRates, [
            h(BestOfferIcon, { width: 16, height: 16 }),
            h(MemberRatesLabel, 'meinHC-Vorteil'),
        ]),
    ]);
};

export default BookingsCard;

BookingsCard.contextTypes = {
    config: PropTypes.object,
};
