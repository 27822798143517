import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useState } from 'react';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import Page from './Page';
import { NavigationTabs } from './navigation/NavigationBar';
import Redemptions from './myVouchers/Redemptions';
import OpenVouchers from './myVouchers/OpenVouchers';
import { pxToRem } from '../styles/unitConverter';

const MyVouchersContainer = glamorous.div({
    fontFamily: options.fonts.default,
    color: options.colors.black,
    maxWidth: pxToRem(1200),
    margin: '0 auto',
});

const Tab = glamorous.a(({ isSelected }) => ({
    textDecoration: 'none',
    color: isSelected ? options.colors.black : options.colors.gray40,
    paddingBottom: options.space.m,
    borderBottom: isSelected ? `2px solid ${options.colors.link}` : 'none',
    '&:hover': {
        color: options.colors.black,
        borderBottom: `2px solid ${options.colors.link}`,
    },
}));
Tab.displayName = 'Tab';

const getVouchersStatusIdentifier = (location) => {
    const hash = location.hash;
    const validHash = ['#open', '#redeemed'];

    return hash && validHash.includes(hash.toLowerCase()) ? hash : '#redeemed';
};

const handleTabClick = (
    { window, location, vouchersStatus },
    { setVouchersStatus },
) => {
    window.history.pushState({}, '', location.pathname);
    setVouchersStatus(vouchersStatus);
};

function MyVouchers(props, { location, window }) {
    const [vouchersStatus, setVouchersStatus] = useState(
        getVouchersStatusIdentifier(location),
    );

    return h(
        Page,
        {
            pagename: '/mhc/vouchers',
            headline: 'Meine Gutscheine',
        },
        [
            h(MyVouchersContainer, [
                h(NavigationTabs, [
                    h(
                        Tab,
                        {
                            onClick: () =>
                                handleTabClick(
                                    {
                                        window,
                                        location,
                                        vouchersStatus: '#open',
                                    },
                                    { setVouchersStatus },
                                ),
                            isSelected: vouchersStatus === '#open',
                        },
                        'Aktuelle',
                    ),
                    h(
                        Tab,
                        {
                            onClick: () =>
                                handleTabClick(
                                    {
                                        window,
                                        location,
                                        vouchersStatus: '#redeemed',
                                    },
                                    { setVouchersStatus },
                                ),
                            isSelected: vouchersStatus === '#redeemed',
                        },
                        'Eingelöste',
                    ),
                ]),
                h(Redemptions, { vouchersStatus }),
                h(OpenVouchers, { vouchersStatus }),
            ]),
        ],
    );
}

MyVouchers.contextTypes = {
    elementModifiers: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    window: PropTypes.object.isRequired,
};

export default MyVouchers;
