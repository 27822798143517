import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { useQuery } from '@apollo/client';
import { Fragment } from 'react';
import { oneLine } from 'common-tags';

import {
    waveMdMediaQuery,
    waveSmMediaQuery,
    waveXsOnlyMediaQuery,
} from '../../styles/waveMediaQueries';
import { waveFontL, waveHeadline2XSSlim } from '../../styles/waveHeadlines';
import { waveTextLBold, waveTextMBold } from '../../styles/waveText';
import { personalVouchers } from '../../queries/personalVouchers';
import TransitionScreen from '../navigation/TransitionScreen';
import Hint from './Hint';
import Voucher from './Voucher';
import { pxToRem } from '../../styles/unitConverter';
import { noticeError } from '../../lib/newrelic';
import PremiumCashbackVouchersHint from './PremiumCashbackVouchersHint';

const Link = glamorous.a({
    textDecoration: 'none',
    margin: `0 ${options.space['2xs']}px`,
});

const RedemptionSteps = glamorous.div({
    padding: `${options.space['3xl']}px 0 ${options.space['6xl']}px 0`,
    borderTop: `1px solid ${options.colors.gray90}`,
    borderBottom: `1px solid ${options.colors.gray90}`,
});

const RedemptionStepsHeadline = glamorous.div({
    textAlign: 'center',
    ...waveTextLBold,
});

const Steps = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    [waveMdMediaQuery]: {
        flexDirection: 'row',
        margin: `0 ${options.space.xs}px`,
    },
});

const Step = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33.33%',
    gap: options.space.s,
    alignItems: 'center',
    marginTop: options.space.xl,
    textAlign: 'center',
});

const StepCounter = glamorous.div({
    width: '40px',
    borderRadius: '50%',
    height: '40px',
    border: `1px solid ${options.colors.blue}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: options.colors.blue,
    ...waveTextLBold,
});
const ToDo = glamorous.div({
    ...waveTextMBold,
});
const Description = glamorous.div({});

const NoVouchersText = glamorous.div({
    [waveXsOnlyMediaQuery]: {
        ...waveFontL,
    },
    ...waveHeadline2XSSlim,
    textAlign: 'center',
    margin: `${options.space['5xl']}px 0 ${options.space['8xl']}px 0`,
});

const VoucherList = glamorous.div({
    display: 'flex',
    padding: `${options.space['3xl']}px 0`,
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: pxToRem(options.space.l),
    [waveSmMediaQuery]: {
        gap: pxToRem(options.space.m),
    },
    [waveMdMediaQuery]: {
        gap: pxToRem(options.space.l),
    },
});

const LinkToBankDataSubmit = h(
    Link,
    {
        href: '/mhc/meine-gutscheine',
        target: '_blank',
    },
    'hier',
);

const redemptionsToDos = [
    {
        step: 'Traumurlaub finden',
        description: 'Traumurlaub auf HolidayCheck finden und direkt buchen',
    },
    {
        step: 'Gutscheincode eingeben',
        description: 'Bei der Buchung Deinen Gutscheincode eingeben',
    },
    {
        step: 'Bankverbindung eingeben',
        descriptionWithLink: [
            'Nach Reiserückkehr Geld aufs Bankkonto überwiesen bekommen:',
            LinkToBankDataSubmit,
            'eingeben',
        ],
    },
];

const OpenVouchers = ({ vouchersStatus }, { userId }) => {
    if (vouchersStatus !== '#open') {
        return null;
    }

    const { loading, error, data } = useQuery(personalVouchers);

    if (loading) {
        return h(TransitionScreen);
    }

    if (error) {
        noticeError(error.message, {
            pageName: 'MyVouchers',
            component: 'OpenVouchers',
            userId,
        });

        return h(Fragment, [
            h(
                Hint,
                {
                    prefix: 'Fehler:',
                    type: 'error',
                },
                oneLine`Wir können Deine Gutscheine derzeit nicht abholen.
         Wir arbeiten daran, das Problem zu beheben.
         Bitte versuchen Sie es später noch einmal`,
            ),
        ]);
    }

    const openVouchers = data.personalVouchersForUser.items.filter(
        (i) => i.status === 'ACTIVE',
    );

    if (!openVouchers.length) {
        return h(Fragment, [
            h(
                NoVouchersText,
                'Im Moment hast Du leider keine aktuellen Gutscheine.',
            ),
            h(PremiumCashbackVouchersHint),
        ]);
    }

    return h(Fragment, [
        h(
            VoucherList,
            openVouchers.map((voucher) => h(Voucher, { voucher })),
        ),
        h(PremiumCashbackVouchersHint),
        h(RedemptionSteps, [
            h(RedemptionStepsHeadline, 'So löst Du Deine Gutscheine ein'),
            h(Steps, [
                ...redemptionsToDos.map(
                    ({ step, description, descriptionWithLink }, index) =>
                        h(Step, [
                            h(StepCounter, index + 1),
                            h(ToDo, step),
                            h(Description, description || descriptionWithLink),
                        ]),
                ),
            ]),
        ]),
    ]);
};

export default OpenVouchers;
