import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import glamorous from 'glamorous';

import BookingOverview from './BookingOverview';
import BookingHeaderImage from './BookingHeaderImage';
import BookingDetails from './BookingDetails';
import BookingDetailsButton from './BookingDetailsButton';
import { white, gray20 } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';
import BookingContext from './context/BookingContext';
import { applyOnMobile, applyOnTabletAndUp } from '../../styles/mediaQueries';
import { secondaryFont, tinyFont } from '../../styles/fonts';
import { findFlightService, findHotelService } from './lib/serviceHelpers';
import useIsMobileDevice from '../../lib/hooks/useIsMobileDevice';

const { headlineS, headlineXS, UITextM } = secondaryFont;

const borderRadiusDesktop = pxToRem(16);
const borderRadiusMobile = pxToRem(4);

const Grid = glamorous.div(({ showDetails }) => ({
    backgroundColor: white,
    boxShadow: `0px ${pxToRem(1)} ${pxToRem(4)} rgba(0, 8, 32, 0.2)`,

    margin: '0 auto',
    width: 'auto',
    maxWidth: pxToRem(1200),

    display: 'grid',
    alignItems: 'flex-start',
    marginTop: pxToRem(24),

    [applyOnTabletAndUp]: {
        borderRadius: borderRadiusDesktop,
        gap: pxToRem(24),
        // adding an extra unused line (". .") to have the bottom gap for the last visible row
        gridTemplateAreas: showDetails
            ? '"image overview" "sidebar content" ". content" ". ."'
            : '"image overview"',
        gridTemplateColumns: '1fr 2fr',
    },
    [applyOnMobile]: {
        borderRadius: borderRadiusMobile,
        gap: pxToRem(20),
        // adding an extra unused line (".") to have the bottom gap for the last visible row
        gridTemplateAreas: showDetails
            ? '"image" "overview" "sidebar" "content" "."'
            : '"image" "overview" "."',
    },
}));
Grid.displayName = 'Grid';

const BookingImageWrapper = glamorous.div(({ showDetails }) => ({
    gridArea: 'image',

    margin: 0,
    width: 'auto',
    height: '100%',

    [applyOnTabletAndUp]: {
        // don't round bottom-left border if we are showing the details below
        borderRadius: showDetails
            ? `${borderRadiusDesktop} 0 0 0`
            : `${borderRadiusDesktop} 0 0 ${borderRadiusDesktop}`,
    },
    [applyOnMobile]: {
        borderRadius: `${borderRadiusMobile} ${borderRadiusMobile} 0 0`,
    },

    // avoids internal image to ruin the corners defined by our borderRadius
    overflow: 'hidden',
    // fixes bug on Safari where dark overlay is overflowing the parent
    zIndex: 1,
}));

const OverviewArea = glamorous.div({
    gridArea: 'overview',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    height: '100%',

    gap: pxToRem(8),
    padding: `${pxToRem(24)} ${pxToRem(28)} ${pxToRem(24)} 0`,

    // include padding in sizing to prevent overflow
    boxSizing: 'border-box',

    [applyOnMobile]: {
        marginInline: pxToRem(20),
        padding: 0,
    },
});

const BookingIdOverview = glamorous.div(tinyFont, {
    textAlign: 'left',
    color: gray20,
});
BookingIdOverview.displayName = 'BookingIdOverview';

const TripHeading = glamorous.h2(headlineS, {
    lineHeight: pxToRem(40),
    textAlign: 'left',
    marginBottom: pxToRem(4),
    marginTop: 0,

    [applyOnMobile]: headlineXS,
});
TripHeading.displayName = 'TripHeading';

const Location = glamorous.div(UITextM, {
    textAlign: 'left',
    marginBottom: 0,
});
Location.displayName = 'Location';

const LocationImage = glamorous.img({
    marginRight: pxToRem(8),
    verticalAlign: 'text-top',
});
LocationImage.displayName = 'LocationImage';

const getFlightLocation = (flight) => {
    const outboundFlightDetails = flight.detail.outBound;
    if (outboundFlightDetails && outboundFlightDetails.length) {
        const [lastOutboundFlight] = outboundFlightDetails.slice(-1);
        return (
            lastOutboundFlight.arrival.airportName ||
            lastOutboundFlight.arrival.airportCode
        );
    }
    return null;
};

const mapTravelkindForTracking = (travelkind) => {
    switch (travelkind) {
        case 'PACKAGE':
            return 'LaPa';
        case 'HOTEL':
            return 'EA';
        default:
            return travelkind.toLowerCase();
    }
};

const trackShowDetail = (trackEventWithBooking, index, booking) => {
    const { startDate, endDate, tourOperator, travelkind, bookingType } =
        booking;
    trackEventWithBooking({
        event: 'event',
        eventCategory: 'mybooking',
        eventAction: 'showDetails',
        eventLabel: index + 1,
        trDepartureDate: startDate,
        trReturnDate: endDate,
        ofEAorLaPa: mapTravelkindForTracking(travelkind),
        trBookingType: bookingType.toLowerCase(),
        ofOrganizerSelected: tourOperator.name || tourOperator.id,
    });
};

const COMBINE_OFFLINE_AND_ONLINE_DATA_PURPOSE =
    'Verbesserung des Kundenservices';

// eslint-disable-next-line complexity
const Booking = (
    { index, booking, alwaysShowDetails },
    { trackEvent, config: { assetsPath }, window },
) => {
    const [showDetails, setShowDetails] = useState(alwaysShowDetails);

    const trackEventWithBooking = (event) => {
        trackEvent({
            ...event,
            mhcBookingId: window.hasConsentedToTrackEvents
                ? booking.midofficeId
                : undefined,
        });
    };

    const trackEventWithBookingContext = (event) => {
        trackEvent({
            event: 'event',
            ...event,
            mhcBookingId: window.hasConsentedToTrackEvents
                ? booking.midofficeId
                : undefined,
        });
    };

    useEffect(() => {
        if (alwaysShowDetails) {
            trackShowDetail(trackEventWithBooking, index, booking);
        }
    }, []);

    const hotel = findHotelService(booking.services);
    const isCancelled = booking.bookingType === 'CANCELLATION';
    const isMobile = useIsMobileDevice(window);

    return h(
        BookingContext.Provider,
        { value: { trackEventWithBooking, trackEventWithBookingContext } },
        h(Grid, { showDetails }, [
            h(
                BookingImageWrapper,
                { showDetails },
                h(BookingHeaderImage, {
                    booking,
                    hotel,
                    isCancelled,
                    isMobile,
                }),
            ),
            h(OverviewArea, [
                (Boolean(hotel) ||
                    Boolean(findFlightService(booking.services))) &&
                    h('div', [
                        h(
                            TripHeading,
                            hotel ? hotel.detail.name : 'Flugbuchung',
                        ),
                        h(Location, [
                            h(LocationImage, {
                                src: `${assetsPath}/myBookings/location--solid.svg`,
                                alt: 'Standort',
                            }),
                            hotel
                                ? `${hotel.detail.regionName}, ${hotel.detail.countryName}`
                                : getFlightLocation(
                                      findFlightService(booking.services),
                                  ),
                        ]),
                    ]),
                h(
                    glamorous.div({
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        alignItems: 'end',
                        [applyOnMobile]: {
                            gridTemplateColumns: '1fr',
                        },
                    }),
                    [
                        h(glamorous.div({ flex: '1 1 auto' }), [
                            h(
                                BookingIdOverview,
                                `HolidayCheck-Buchungsnummer: ID${booking.midofficeId}`,
                            ),
                            h(BookingDetailsButton, {
                                onClick: () => {
                                    setShowDetails(!showDetails);
                                    trackShowDetail(
                                        trackEventWithBooking,
                                        index,
                                        booking,
                                    );
                                },
                                isExpanded: showDetails,
                                hotelName: hotel ? hotel.detail.name : null,
                            }),
                        ]),
                        !isMobile &&
                            h(BookingOverview, {
                                booking,
                                isCancelled,
                                shouldBeWhite: isMobile,
                            }),
                    ],
                ),
            ]),
            h(BookingDetails, { booking, showDetails, isMobile }),
        ]),
    );
};

Booking.propTypes = {
    index: PropTypes.number.isRequired,
    booking: PropTypes.PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        travelDurationDays: PropTypes.number.isRequired,
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            selfServicePortal: PropTypes.shape({
                url: PropTypes.string.isRequired,
                description: PropTypes.string,
                features: PropTypes.arrayOf(
                    PropTypes.shape({
                        key: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        }).isRequired,
        travelkind: PropTypes.string.isRequired,
        bookingType: PropTypes.string.isRequired,
    }).isRequired,
    alwaysShowDetails: PropTypes.bool.isRequired,
};

Booking.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
    window: PropTypes.object.isRequired,
};

export default Booking;
