import gql from 'graphql-tag';

export const personalVouchers = gql`
    query {
        personalVouchersForUser {
            items {
                id
                category
                status
                code
                endDate
                cashbackRules {
                    price {
                        amount
                        currency
                    }
                    travelMinPrice {
                        amount
                        currency
                    }
                }
            }
        }
    }
`;
