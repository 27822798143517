import glamorous from 'glamorous';
import options from '@designsystem/options';
import h from 'react-hyperscript';

import { waveXsOnlyMediaQuery } from '../../styles/waveMediaQueries';
import { waveFontL, waveHeadline2XSSlim } from '../../styles/waveHeadlines';
import Info from '../icons/Info';

const Disclaimer = glamorous.div({
    fontFamily: options.fonts.body,
    margin: `${options.space.xs}px 0`,
    textAlign: 'center',
    fontSize: options.fontSizes.s,
});

const Hint = glamorous.div({
    [waveXsOnlyMediaQuery]: {
        ...waveFontL,
    },
    ...waveHeadline2XSSlim,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: options.space['2xs'],
});

const Link = glamorous.a({
    textDecoration: 'none',
    margin: `0 ${options.space['2xs']}px`,
});

const PremiumCashbackVouchersHint = () =>
    h(Disclaimer, [
        h(Hint, [
            h(Info, {
                css: {
                    width: '20px',
                    height: '20px',
                },
            }),
            'Hinweis',
        ]),
        h('span', 'Premium-Mitglieder finden ihre zusätzlichen Gutscheine'),
        h(
            Link,
            {
                href: '/mhc/mein-premium',
                target: '_blank',
            },
            'hier',
        ),
    ]);

export default PremiumCashbackVouchersHint;
