import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { waveHeadlineS } from '../../../../styles/waveHeadlines';
import { waveTextSBold, waveTextM } from '../../../../styles/waveText';
import { voucherGradient } from '../../../../styles/waveColors';
import { ButtonTextDefault, ButtonTextMedium, CtaButtonWhite } from './Common';

const VoucherSection = glamorous.div({
    background: voucherGradient,
    color: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
});

const VoucherHeader = glamorous.div(waveHeadlineS);

const VoucherSubheader = glamorous.div(waveTextSBold);

const VoucherExpirationDate = glamorous.div({
    ...waveTextM,
    marginBottom: `${options.space.m}px`,
});

const VoucherIssuedCard = ({ voucher, trackEvent }) => {
    return h('div', [
        h(VoucherSection, [
            h(VoucherHeader, [voucher.value]),
            h(VoucherSubheader, 'Reisegutschein'),
            h(VoucherExpirationDate, `Gültig bis ${voucher.validUntil}`),
            h(
                CtaButtonWhite,
                {
                    href: '/einstellungen/meine-gutscheine',
                    onClick: () =>
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub',
                            eventAction: 'gotovouchers',
                            eventLabel: 'Meine Gutscheine',
                        }),
                },
                [
                    h(ButtonTextDefault, 'Meine Gutscheine'),
                    h(ButtonTextMedium, 'Gutscheine'),
                ],
            ),
        ]),
    ]);
};

export default VoucherIssuedCard;

VoucherIssuedCard.contextTypes = {
    config: PropTypes.object,
};
