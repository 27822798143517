import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { ExclamationMarkFilled } from '@designsystem/icons';

import {
    waveTextSBold,
    waveTextMBold,
    waveText2XSBold,
} from '../../../../styles/waveText';
import { voucherGradient } from '../../../../styles/waveColors';
import { waveHeadlineS } from '../../../../styles/waveHeadlines';
import { ButtonTextDefault, ButtonTextMedium, CtaButtonBlue } from './Common';

const VoucherSection = glamorous.div({
    background: voucherGradient,
    color: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
});

const VoucherHeader = glamorous.div(waveHeadlineS);

const VoucherSubheader = glamorous.div(waveTextSBold);

const VoucherNotIssuedAlert = glamorous.div({
    display: 'inline-flex',
    alignItems: 'center',
});

const VoucherNotIssuedAlertText = glamorous.span({
    marginLeft: options.space['2xs'],
});

const ConditionsHeader = glamorous.div(waveTextMBold);

const ConditionsExpirationNotice = glamorous.span({
    ...waveText2XSBold,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: options.radii.small,
    borderColor: options.colors.blue90,
    padding: options.space['2xs'],
    justifyContent: 'center',
    color: options.colors.blue20,
    backgroundColor: options.colors.blue90,
});

const VoucherNotIssuedCard = ({ voucher, trackEvent }) => {
    const ConditionsSection = glamorous.div({
        backgroundColor: options.colors.white,
        padding: `${options.space.l}px ${options.space.xl}px`,
        display: 'flex',
        flexDirection: 'column',
        gap: `${options.space.m}px`,
    });

    return h('div', [
        h(VoucherSection, [
            h(VoucherHeader, [voucher.value]),
            h(VoucherSubheader, 'Reisegutschein'),
            h(VoucherNotIssuedAlert, [
                h(ExclamationMarkFilled),
                h(VoucherNotIssuedAlertText, 'Noch nicht freigeschaltet'),
            ]),
        ]),
        h(ConditionsSection, [
            h('div', [
                h(
                    ConditionsExpirationNotice,
                    `Aktion bis ${voucher.validUntil}`,
                ),
            ]),
            h(
                ConditionsHeader,
                'Fülle Deine Reisevorlieben aus & erhalte einen Reisegutschein!',
            ),
            h(
                'div',
                `Du kannst diesen ${voucher.value} Gutschein freischalten, indem Du uns Deine Reisevorlieben wie z.B. den Wunschflughafen bis zum ${voucher.validUntil} mitteilst.`,
            ),
            h(
                CtaButtonBlue,
                {
                    href: '/einstellungen/meine-reisevorlieben',
                    onClick: () =>
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub',
                            eventAction: 'gotopreferences',
                            eventLabel: 'Meine Reisevorlieben',
                        }),
                },
                [
                    h(ButtonTextDefault, 'Meine Reisevorlieben'),
                    h(ButtonTextMedium, 'Reisevorlieben'),
                ],
            ),
        ]),
    ]);
};

export default VoucherNotIssuedCard;

VoucherNotIssuedCard.contextTypes = {
    config: PropTypes.object,
};
