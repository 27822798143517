import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import moment from 'moment';

import { pxToRem } from '../../styles/unitConverter';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import {
    blueGradient,
    premiumGradient,
    premiumGradientHover,
} from '../../styles/waveColors';
import {
    waveFontM,
    waveHeadline2XSSlim,
    waveHeadline3xl,
} from '../../styles/waveHeadlines';
import { waveTextMBold, waveTextMButton } from '../../styles/waveText';
import Clipboard from '../common/Clipboard';

const isClubCategory = (campaignCategory) => campaignCategory === 'Club';

const VoucherContainer = glamorous.div({
    boxShadow: options.shadows.raised,
    borderRadius: pxToRem(options.space.xs),
    overflow: 'hidden',
    flex: '1 0 100%',
    maxWidth: pxToRem(384),
    display: 'flex',
    flexDirection: 'column',
    [waveSmMediaQuery]: {
        flex: '1 0 40%',
    },
    [waveMdMediaQuery]: {
        flex: '1 0 33%',
    },
});

const VoucherValueContainer = glamorous.div(({ campaignCategory }) => ({
    background: isClubCategory(campaignCategory)
        ? premiumGradient
        : blueGradient,
    color: options.colors.white,
    textAlign: 'center',
    padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.m)} ${pxToRem(options.space.xl)}`,
    [waveSmMediaQuery]: {
        padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.m)} ${pxToRem(options.space.l)}`,
    },
    [waveMdMediaQuery]: {
        padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.xl)} ${pxToRem(options.space['2xl'])}`,
    },
}));

const VoucherAmountContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    gap: pxToRem(options.space.s),
    justifyContent: 'center',
});

const VoucherValueUptoContainer = glamorous.div({
    marginTop: 'auto',
    paddingBottom: pxToRem(14),
});

const VoucherValue = glamorous.div({
    ...waveHeadline3xl,
});

const VoucherTypeContainer = glamorous.div({
    ...waveHeadline2XSSlim,
    marginTop: pxToRem(-4),
});

const VoucherValidityContainer = glamorous.div({
    marginTop: pxToRem(options.space.l),
});

const VoucherValidity = glamorous.div({ ...waveFontM });

const VoucherRedeemInformationContainer = glamorous.div({
    textAlign: 'center',
    padding: pxToRem(options.space.xl),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const VoucherRedeemInformationText = glamorous.div({ textAlign: 'left' });

const VoucherRedeemButton = glamorous.a(({ campaignCategory }) => ({
    background: isClubCategory(campaignCategory)
        ? premiumGradient
        : options.colors.blue,
    borderRadius: pxToRem(100),
    padding: `${pxToRem(options.space.xs)} ${pxToRem(options.space.m)}`,
    display: 'block',
    color: options.colors.white,
    ...waveTextMButton,
    textDecoration: 'none',
    textTransform: 'uppercase',
    border: `1px solid ${options.colors.blue}`,
    ...waveTextMBold,
    '&:hover': {
        background: isClubCategory(campaignCategory)
            ? premiumGradientHover
            : options.colors.blue20,
    },
}));

const VoucherCodeContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontStyle: 'normal',
    padding: `${options.space.s}px 0 ${options.space.l}px 0`,
    ...waveTextMBold,
});

const getMaxVoucherValue = (cashbackRules) =>
    Math.max(...cashbackRules.map(({ price }) => price.amount));

const getMinTravelPriceThreshold = (cashbackRules) => {
    const cashbackRulesWithMinThreshold = cashbackRules
        .map(({ travelMinPrice }) => travelMinPrice && travelMinPrice.amount)
        .filter(Boolean);

    if (!cashbackRulesWithMinThreshold.length) return null;

    return Math.min(...cashbackRulesWithMinThreshold);
};

const Voucher = ({ voucher }) => {
    const isStaggered = voucher.cashbackRules.length >= 2;
    const minimumTravelPriceThreshold = getMinTravelPriceThreshold(
        voucher.cashbackRules,
    );

    return h(VoucherContainer, [
        h(VoucherValueContainer, [
            h(VoucherAmountContainer, [
                isStaggered && h(VoucherValueUptoContainer, `bis zu `),
                h(
                    VoucherValue,
                    `${getMaxVoucherValue(voucher.cashbackRules)}€`,
                ),
            ]),
            h(VoucherTypeContainer, 'Reisegutschein'),
            h(VoucherValidityContainer, [
                h(
                    VoucherValidity,
                    `Gültig bis ${moment(voucher.endDate).format('DD.MM.YYYY')}`,
                ),
            ]),
        ]),
        h(VoucherRedeemInformationContainer, [
            h(VoucherRedeemInformationText, [
                h(
                    'span',
                    `Dieser Gutschein ist einlösbar ${minimumTravelPriceThreshold ? `ab ${minimumTravelPriceThreshold} € Reisepreis, ` : ''}für Pauschalreisen oder Hotelbuchungen`,
                ),
            ]),
            h(VoucherCodeContainer, [
                h('div', 'Gutscheincode'),
                h(Clipboard, {
                    value: voucher.code,
                }),
            ]),
            h(
                VoucherRedeemButton,
                {
                    href: '/',
                },
                'Urlaub Finden',
            ),
        ]),
    ]);
};

export default Voucher;
