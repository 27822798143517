import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { ExclamationMarkFilled } from '@designsystem/icons';

import { waveTextSBold, waveTextMBold } from '../../../../styles/waveText';
import { voucherGradient } from '../../../../styles/waveColors';
import { CtaButtonBlue, HeaderTextDefault, HeaderTextMedium } from './Common';

const ConditionsSection = glamorous.div({
    backgroundColor: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.m}px`,
});

const HeaderSection = glamorous.div({
    background: voucherGradient,
    color: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
});

const Header = glamorous.div(waveTextSBold);

const AlertContainer = glamorous.div({
    display: 'inline-flex',
    alignItems: 'center',
});

const AlertText = glamorous.span({
    marginLeft: options.space['2xs'],
});

const ConditionsHeader = glamorous.div(waveTextMBold);

const VoucherTermsCard = (props, { trackEvent }) => {
    return h('div', [
        h(HeaderSection, [
            h(Header, [
                h(HeaderTextDefault, 'Nutzungsbedingungen'),
                h(HeaderTextMedium, 'Nutzungs-bedingungen'),
            ]),
            h(AlertContainer, [
                h(ExclamationMarkFilled),
                h(AlertText, 'Noch nicht bestätigt'),
            ]),
        ]),
        h(ConditionsSection, [
            h(
                ConditionsHeader,
                'Bitte lies und akzeptiere unsere neuen Nutzungsbedingungen',
            ),
            h(
                CtaButtonBlue,
                {
                    href: '/einstellungen/meine-accept-tnc?from=mhc-hub',
                    onClick: () =>
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub',
                            eventAction: 'gotoaccepttnc',
                            eventLabel: 'AKZEPTIEREN',
                        }),
                },
                ['AKZEPTIEREN'],
            ),
        ]),
    ]);
};

export default VoucherTermsCard;

VoucherTermsCard.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
};
